export const track = (eventName, properties) => {
  heap.track(eventName, properties)
}

export const identify = (identity) => {
  heap.identify(identity)
}

export const resetIdentity = () => {
  heap.resetIdentity()
}

export const addUserProperties = (properties) => {
  heap.addUserProperties(properties)
}

export const addEventProperties = (properties) => {
  heap.clearEventProperties()
  heap.addEventProperties(properties)
}