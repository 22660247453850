import { Controller } from "stimulus";

// Connects to data-controller="school-users-registration"
export default class extends Controller {
  static targets = ["name", "email", "prodigyPolicies", "signUpButton"];

  handleFormInput() {
    this.isValid(
      this.nameInput.value,
      this.emailInput.value,
      this.prodigyPoliciesInput.checked
    )
      ? (this.signUpButton.disabled = false)
      : (this.signUpButton.disabled = true);
  }

  isValid(namePresent, emailPresent, policiesChecked) {
    return namePresent && emailPresent && policiesChecked;
  }

  get nameInput() {
    return this.nameTarget;
  }

  get emailInput() {
    return this.emailTarget;
  }

  get prodigyPoliciesInput() {
    return this.prodigyPoliciesTarget;
  }

  get signUpButton() {
    return this.signUpButtonTarget;
  }
}
