import Choices from 'choices.js';

(function() {

  var elem = document.getElementById('select-container');
  if (elem != undefined)
  {
    var choices = new Choices('.js-choices', { removeItemButton: true, placeholder: true, placeholderValue: 'search school', searchResultLimit: 15 });
    elem.style.display = 'block';
  }

}).call(this);
