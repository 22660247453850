import {track, identify} from './index'

const heapData = document.querySelector('meta[name="heap_information"]')

if (heapData) {
  const { attributes } = heapData

  const isAdmin = attributes['data-isadmin'].value === "true"
  const currentUser = JSON.parse(attributes['data-currentuser'].value)

  if (isAdmin) {  
    identify(currentUser.email)
  } else {
    identify(currentUser.id)
  }

  const tabs = document.getElementsByClassName('pf-tabs')
  if (tabs.length) {
    const {children} = tabs[0]
    if (children.length) {
      let tabIds = []
      for (let i = 0; i < children.length; i++) {
        tabIds.push(children[i].id)
      }
      for (let i = 0; i < tabIds.length; i++) {
        const element = document.getElementById(tabIds[i])
        element.addEventListener('click', () => {
          track('Click - School Report Tab', {"Report Tab": tabIds[i], "Is Admin": isAdmin, "Job Title": currentUser.job_title || null, "Role Type": currentUser.role_type || null})
        })
      }
    }
  }
}